import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { LeaveService } from "src/app/services/leave.service";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "add-leaves",
  templateUrl: "./addleave.component.html",
  styleUrls: ["./addleave.component.css"],
})
export class AddLeaveComponent implements OnInit {
  form: FormGroup;
  successfulSave: boolean;
  errors: any = [];
  absenceTypes: any = [];
  balance: number = 0;
  days: number = 0;
  isCalendarHolidays: boolean;

  constructor(
    public utilsService: UtilsService,
    public exchangeService: ExchangeService,
    private dataService: DataService,
    private employeeService: EmployeeService,
    private leaveService: LeaveService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.dataService
      .dictionary(1000004)
      .subscribe((s) => (this.absenceTypes = s));
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      AbsenceTypeId: [null, Validators.required],
      FromDate: [null, Validators.required],
      ToDate: [null, Validators.required],
      Acting: [""],
      BusinessPlace: [""],
      BusinessPurpose: [""],
      Comment: [""],
    });
  }
  changeabsence(event) {
    var balanceform = this.form.value;

    if (this.form.value.FromDate && this.form.value.ToDate) {
      balanceform.FromDate = this.form.value.FromDate.format("YYYY-MM-DD");
      balanceform.ToDate = this.form.value.ToDate.format("YYYY-MM-DD");
      this.leaveService.balance(balanceform).subscribe((b: any) => {
        this.balance = b.balance as number;
        this.days = b.leaveDays as number;
        this.isCalendarHolidays = b.isCalendarHolidays as boolean;
      });
      this.clearerrors();
    }
    // switch (this.form.value.AbsenceTypeId)
    // {
    //   case 500:

    // }
  }

  getdayes(event) {
    // this.days = this.utilsService.DaysDiff(
    //   this.form.value.FromDate,
    //   this.form.value.ToDate
    // );
    this.changeabsence(event);
  }
  clearerrors() {
    this.errors = [];
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].setErrors(null);
    });
  }
  submit() {
    if (!this.form.valid) {
      return;
    }
    this.clearerrors();
    this.form.value.FromDate = this.utilsService.Format(
      this.form.value.FromDate,
      "YYYY-MM-DD"
    );
    this.form.value.ToDate = this.utilsService.Format(
      this.form.value.ToDate,
      "YYYY-MM-DD"
    );
    this.leaveService.insert(this.form.value).subscribe(
      (b) => {
        this.successfulSave = true;
        this.router.navigate(["/me/mybalance"]);
      },
      (err) => {
        this.successfulSave = false;
        if (err.status === 400) {
          // handle validation error
          for (var fieldName in err.error) {
            if (err.error.hasOwnProperty(fieldName)) {
              this.errors.push(err.error[fieldName]);
              this.form.controls[fieldName].setErrors(err.error[fieldName]);
            }
          }
        }
      }
    );
  }
}
