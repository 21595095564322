<div class="login_page_wrapper">
  <div class="login_wrapper">
    <div class="mobile_header">
      <div class="logo">
        <img src="../../assets/img/logo_white.png" />
        <p>HR&Payroll Software | HR Digital Co. Ltd</p>
        <div class="icons">
          <!-- Mobile contact info icon -->
          <div class="icon">
            <a href="https://www.facebook.com/DHR.ge/" target="_blank"><i class="fab fa-facebook-f"></i></a>
          </div>
          <!-- Mobile contact info icon -->
          <!-- Mobile contact info icon -->
          <!-- <div class="icon">
            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
          </div> -->
          <!-- Mobile contact info icon -->
          <!-- Mobile contact info icon -->
          <div class="icon">
            <a href="https://www.linkedin.com/in/hr-digital-corporation-2921a3221" target="_blank"><i class="fab fa-linkedin-in"></i></a>
          </div>
          <!-- Mobile contact info icon -->
          <!-- Mobile contact info icon + content -->
          <div class="icon">
            <input type="checkbox" />
            <i class="fas fa-phone"></i>
            <i class="fas fa-times"></i>
            <div class="mobile_info_wrapper">
              <a href="tel:+995 577 110 404">+995 577 110 404</a>
            </div>
          </div>
          <!-- Mobile contact info icon + content -->
          <!-- Mobile contact info icon + content -->
          <div class="icon">
            <input type="checkbox" />
            <i class="far fa-envelope-open "></i>
            <i class="fas fa-times"></i>
            <div class="mobile_info_wrapper">
              <a href="mailto:info@digitalhr.ge">info@digitalhr.ge</a>
            </div>
          </div>
          <!-- Mobile contact info icon + content -->
          <!-- Mobile contact info icon + content -->
          <div class="icon">
            <input type="checkbox" />
            <i class="fas fa-map-pin"></i>
            <i class="fas fa-times"></i>
            <div class="mobile_info_wrapper">
              თბილისი, ალ. ყაზბეგის 34ა
            </div>
          </div>
          <!-- Mobile contact info icon + content -->
          <!-- Mobile contact info icon + content -->
          <div class="icon">
            <input type="checkbox" />
            <i class="fas fa-paper-plane"></i>
            <i class="fas fa-times"></i>
            <div class="mobile_info_wrapper">
              <a href="https://www.digitalhr.ge" target="_blank">www.digitalhr.ge</a>
            </div>
          </div>
          <!-- Mobile contact info icon + content -->
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="site_info_wrapper">
    <!-- Header -->
    <div class="header">
      <!-- Logo -->
      <div class="logo">
        <img src="../../assets/img/logo.png" />
        <p>HR&Payroll Software | HR Digital Co. Ltd</p>
      </div>
      <div class="contact_info">
        <!-- Social icons -->
        <div class="icons">
          <a href="https://www.facebook.com/DHR.ge/" target="_blank"><i class="fab fa-facebook-f "></i></a>
          <!-- <a href="#" target="_blank"><i class="fab fa-instagram "></i></a> -->
          <a href="https://www.linkedin.com/in/hr-digital-corporation-2921a3221" target="_blank"><i class="fab fa-linkedin-in "></i></a>
        </div>
        <!-- Contact info -->
        <div class="other_info">
          <p><a href="tel:+995 577 110 404"><i class="fas fa-phone "></i> +995 577 110 404</a></p>
          <p><a href="mailto:info@digitalhr.ge"><i class="far fa-envelope-open "></i> info@digitalhr.ge</a></p>
          <p><i class="fas fa-map-pin "></i> თბილისი, ალ. ყაზბეგის 34ა</p>
          <p><a href="https://www.digitalhr.ge" target="_blank"><i class="fas fa-paper-plane "></i> www.digitalhr.ge</a></p>
        </div>
      </div>
    </div>
    <!-- Header -->
    <div class="container-fluid">
      <!-- Main page post -->
      <div class="row post_block">
        <div class="col-12 col-md-4">
          <img src="../../assets/img/img_1.jpg" />
        </div>
        <div class="col-12 col-md-8">
          <p class="title">ჩვენ შესახებ</p>
          <div class="description"><p>HR Digital Co. - ჩვენ ვართ ადამიანური რესურსების მართვის&nbsp; პროფესიონალთა გუნდი მრავალწლიანი მენეჯერული გამოცდილებით.</p>
            <p>კომპანიის აუთსორსინგული მომსახურებით ვეხმარებით მცირე და საშუალო ბიზნესს წარმატების მიღწევაში.</p>
            <p>ჩვენი პლატფორმა DHR.ge განკუთვნილია როგორც დამსაქმებლის, ასევე დასაქმებულის&nbsp; საკომუნიკაციო&nbsp; კომფორტისა და პროცესების ეფექტური მართვისთვის.</p>
            <p>ჩვენი კომპანიის აუთსორსინგული HR &amp; Payroll და ყველა სხვა სახის სერვისი&nbsp; ორგანიზაციებს საშუალებას აძლევს დაზოგონ დრო და რესურსი, მიიღონ მაღალკვალიფიციური ციფრული მომსახურება სახელმწიფო რეგულაციებისა და საგადასახადო ვალდებულებების შესაბამისად.</p></div>
          <!-- <a href="#" class="read_mode">კითხვის გაგრძელება <i class="fas fa-chevron-right "></i></a> -->
        </div>
      </div>
      <!-- Main page post -->

      <!-- Vacancy -->
      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="vacancy_header">
            <i class="fas fa-user-plus"></i> ვაკანსიები
            <a href="#">ყველას ნახვა</a>
          </div>
          <div class="vacany_wrapper">
            <!-- Vacancy list item -->
            <a href="#" class="vacancy_list">
              <span>
                <i class="fas fa-angle-double-right"></i>
                ვაკანსიის დასახელება
              </span>
            </a>
            <!-- Vacancy list item -->
          <!-- Vacancy list item -->
          <a href="#" class="vacancy_list">
            <span>
              <i class="fas fa-angle-double-right"></i>
              ვაკანსიის დასახელება
            </span>
          </a>
          <!-- Vacancy list item -->
          <!-- Vacancy list item -->
          <a href="#" class="vacancy_list">
            <span>
              <i class="fas fa-angle-double-right"></i>
              ვაკანსიის დასახელება
            </span>
          </a>
          <!-- Vacancy list item -->
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <!-- Presentation -->
          <div class="presentation">
            <img src="../../assets/img/img_2.jpg" />
          </div>
          <!-- Presentation -->
        </div>
        <!-- Ads -->
        <div class="col-6">
          <div class="ads_wrapper">
            <img src="../../assets/img/img_3.jpg" />
            <a href="">გაიგე მეტი</a>
          </div>
        </div>
        <div class="col-6">
          <div class="ads_wrapper">
            <img src="../../assets/img/img_4.jpg" />
            <a href="https://digitalhr.ge/" target="_blank">გაიგე მეტი</a>
          </div>
        </div>
        <!-- Ads -->
      </div>
      <!-- Vacancy -->
    </div>
  </div>
</div>

<!-- More btn clicked container -->
<div class="more_wrapper "><!-- ბლოკის გამოჩენისთვის დაუწერე კლასი active -->
  <button type="button" class="more_wrapper_shadow"></button>
  <div class="more_container">
    <p class="title">რაიმე განცხადების დასახელება</p>
    <div class="description">ნობილი ფაქტია, რომ გვერდის წაკითხვად შიგთავსს შეუძლია მკითხველის ყურადღება მიიზიდოს და დიზაინის აღქმაში ხელი შეუშალოს. Lorem Ipsum-ის გამოყენებით ვღებულობთ იმაზე მეტ-ნაკლებად სწორი გადანაწილების ტექსტს, ვიდრე ერთიდაიგივე გამეორებადი სიტყვებია ხოლმე. შედეგად, ტექსტი ჩვეულებრივ ინგლისურს გავს, მისი წაითხვა კი შეუძლებელია. დღეს უამრავი პერსონალური საგამომცემლო პროგრამა და ვებგვერდი იყენებს Lorem Ipsum-ს, როგორც დროებით ტექსტს წყობის შესავსებად; Lorem Ipsum-ის</div>
  </div>
</div>
<!-- More btn clicked container -->
