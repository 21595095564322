import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent  {
  info;
  readonly apiBaseURI = 'https://api.dhr.ge';
  constructor(private http: HttpClient) {
      this.http.get(this.apiBaseURI +`/feed/weather`).subscribe(
          data => this.info = data,
          error => this.info="ამინდის ინფორმაცია დროებით მიუწვდომელია..."
      );
  }
}
