<table *ngIf="unapproved.length > 0" mat-table class="mat-elevation-z8 table-content" [dataSource]="unapproved">
  <ng-container matColumnDef="personName">
    <th mat-header-cell *matHeaderCellDef> სახელი, გვარი</th>
    <td mat-cell *matCellDef="let element"> {{element.personName}} </td>
  </ng-container>
  <ng-container matColumnDef="absenceTypeName">
    <th mat-header-cell *matHeaderCellDef> შვებულების ტიპი</th>
    <td mat-cell *matCellDef="let element"> {{element.absenceTypeName}} </td>
  </ng-container>
  <ng-container matColumnDef="fromDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებაში გასვლის თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.fromDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="toDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებიდან დაბრუნების თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.toDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef> კალენდარული დღეების რაოდენობა</th>
    <td mat-cell *matCellDef="let element"> {{element.days}} </td>
  </ng-container>
  <ng-container matColumnDef="approve">
    <th mat-header-cell  *matHeaderCellDef >რეაგირება</th>
    <td mat-cell *matCellDef="let element" >
      <button mat-fab aria-label="Example icon button with a heart icon" (click)="approve(element.id);" class="btn_blue">
        <mat-icon>გაგრძელება <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right fa-w-8 fa-7x"><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" class=""></path></svg></mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
