<mat-tab-group #tabTurnstile style="margin-top: 20px" class="home_tabs">
  <mat-tab *ngFor="let ct of categories">
    <ng-template mat-tab-label >
      <img src="../../../assets/svg/document.svg" />
      <span>{{ct}}</span>
    </ng-template>
    <app-pdf *ngFor="let cd of filterFunction(employeedocuments,ct)" [guid]="cd.documentGuid" [name]="cd.name"></app-pdf>
  </mat-tab>
</mat-tab-group>


