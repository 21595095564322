<img src="/assets/img/logo.png" class="mx-auto d-block">
<form #form='ngForm' class="mb-4" autocomplete="off" (submit)="onSubmit(form)">
  <div class="form-group">
    <label>პირადი #</label>
    <input class="form-control" name="UserName" minlength="11" maxlength="11" numbersOnly #UserName="ngModel" [(ngModel)]="formModel.UserName" required>
    <label class="text-danger" *ngIf="UserName.touched && UserName.errors?.required">აუცილებლად შესატანი ველია</label>
    <label class="text-danger" *ngIf="UserName.touched && (UserName.errors?.minlength || UserName.errors?.maxlength)">პირადი # სიგრძე 11 სიმბოლოა</label>
  </div>
  <div class="form-group">
    <label>ერთჯერადი კოდი</label>
    <input class="form-control"  name="OTC" #OTC="ngModel" minlength="6" maxlength="6" numbersOnly  [(ngModel)]="formModel.OTC" required>
    <label class="text-danger" *ngIf="OTC.touched && OTC.errors?.required">აუცილებლად შესატანი ველია</label>
    <label class="text-danger" *ngIf="OTC.touched && (OTC.errors?.minlength || OTC.errors?.maxlength)">კოდის სიგრძე 6 სიმბოლოა</label>
  </div>
    <div class="form-group required">
      <label>პაროლი</label>
      <input type="password" class="form-control" #Password="ngModel" minlength="4" name="Password" [(ngModel)]="formModel.Password" required>
      <label class="text-danger" *ngIf="Password.touched && Password.errors?.required">აუცილებლად შესატანი ველია</label>
      <label class="text-danger" *ngIf="Password.touched && Password.errors?.minlength">პაროლის მინიმალური სიგრძე 4 სიმბოლოა</label>
    </div>
    <div class="form-group required">
      <label>პაროლის გამეორება</label>
      <input type="password"class="form-control" #ConfirmPassword="ngModel" name="ConfirmPassword"  [(ngModel)]="formModel.ConfirmPassword" required>
      <label class="text-danger" *ngIf="ConfirmPassword.touched && ConfirmPassword.errors?.required">აუცილებლად შესატანი ველია</label>
      <label class="text-danger" *ngIf="ConfirmPassword.touched && ConfirmPassword.value!=Password.value">პაროლები არ ემთხვევა</label>
    </div>
    <div class="form-row">
      <div class="form-group col-md-8 offset-md-2">
        <span class="text-danger">{{errmessage}}</span>
      </div>
    </div>
    <div class="form-row">
    <div class="form-group col-md-8 offset-md-2">
      <button type="submit" class="btn btn-lg btn-block" [disabled]="form.invalid">აღდგენა</button>
    </div>
  </div>
</form>
