export enum AuthenticationStatus
{
  OK = 0,
  NonExistingUser = 1,
  NotActivated = 2,
  UserPasswordNotFound = 3,
  OTCNotSent = 4,
  WrongCode = 5,
  ChangingWrongCode=6
};
