import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeService } from "src/app/services/employee.service";
import { ExchangeService } from "src/app/services/exchange.service";

@Component({
  selector: "app-empboard",
  templateUrl: "./empboard.component.html",
  styleUrls: ["./empboard.component.css"],
})
export class EmpboardComponent implements OnInit, OnDestroy {
  employees: any = [];
  empno: string;
  private sub: any;

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    public exchangeService: ExchangeService
  ) {
    this.sub = this.router.events.subscribe((params) => {
      if (this.exchangeService.empno) {
        this.employeeService
          .getemployeesbyCode(this.exchangeService.empno)
          .subscribe((emps) => {
            this.employees = emps;
            this.exchangeService.empno = "";
          });
      } else {
        this.employeeService.getallemployees().subscribe((emps) => {
          this.employees = emps;
        });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub)
    this.sub.unsubscribe();
  }
}
