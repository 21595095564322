import { Component, Input, OnInit } from '@angular/core';
import { DocService } from 'src/app/services/doc.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  imageToShow: any;
  isImageLoading: boolean;
  @Input() imageclass: string = 'photo';
  @Input() alt: string = 'გამოსახულება';
  @Input()
  set imageId(val: any) {
    this.getImage(val);
    }
  constructor(private docservice: DocService) { }

  ngOnInit(): void {

  }

  getImage(guid:string) {
    this.isImageLoading = true;
    this.docservice.getImage(guid).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
}

createImageFromBlob(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShow = reader.result;
  }, false);

  if (image) {
     reader.readAsDataURL(image);
  }
 }

}
