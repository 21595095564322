import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { mergeMap } from "rxjs/operators";
import { ExchangeService } from "src/app/services/exchange.service";
import { TimesheetService } from "src/app/services/timesheet.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-mytimesheet",
  templateUrl: "./mytimesheet.component.html",
  styleUrls: ["./mytimesheet.component.css"],
})
export class MytimesheetComponent implements OnInit {
  timeheader: any;
  sub: any;


  constructor(
    private router: Router,
    private service: UserService,
    private timesheetService: TimesheetService,
    public exchangeService: ExchangeService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.exchangeService.me) {
      var date = new Date();
      this.timesheetService
        .mytimesheet(
          this.exchangeService.me.id,
          this.datePipe.transform(date, "yyyy-MM-dd")
        )
        .subscribe((b) => {
          this.timeheader = b;
        });
    } else {
      this.sub = this.exchangeService.myid
        .pipe(
          mergeMap((id) => {
            var date = new Date();
            return this.timesheetService.mytimesheet(
              id,
              this.datePipe.transform(date, "yyyy-MM-dd")
            );
          })
        )
        .subscribe((b) => {
          this.timeheader = b;
        });
    }
  }

  ngOnDestroy() {
    if (this.sub)
    this.sub.unsubscribe();
  }


}
