import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  empno: string;
  me: any;
  mycompany: any;
  myid= new Subject<string>();
}
