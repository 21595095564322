<div class="tabel_select_mobile">
    <p class="tabel_title">
        <img _ngcontent-bnx-c147="" src="../../../assets/img/users.png">
        თანამშრომლები
    </p>
    <mat-form-field appearance="outline">
     <mat-select (selectionChange)='onOptionsSelected($event)'>
          <mat-option value=undefined>გთხოვთ აირჩიოთ</mat-option>
          <mat-option *ngFor="let emp of myemployees" [value]="emp.id">
              {{emp.fullName}}
          </mat-option>
      </mat-select>
    </mat-form-field>
</div>

<div class="tabel_users_container">
    <p class="tabel_title">
        <img _ngcontent-bnx-c147="" src="../../../assets/img/users.png">
        თანამშრომლები
    </p>
    <div class="select_users">
        <div class="radio_select" *ngFor="let emp of myemployees">
            <input type="radio" name="tabel_user" [value]="emp.id" (change)='onInputChanged(emp.id)' />
            <p>{{emp.fullName}}</p>
        </div>
    </div>
</div>
<div class="tabel_container">
    <app-timesheet [timeheader]="emptimeheader">
    </app-timesheet>
</div>
