import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-unapproved',
  templateUrl: './unapproved.component.html',
  styleUrls: ['./unapproved.component.css']
})
export class UnapprovedComponent implements OnInit {
  unapproved: any = [];
  displayedColumns: string[] = ['personName','absenceTypeName','fromDate','toDate','days','approve'];

  constructor(private router: Router,private leaveService: LeaveService) { }

  ngOnInit(): void {
    this.leaveService.unapproved().subscribe((b) => (this.unapproved = b));
  }

  approve(id)
  {
    this.router.navigateByUrl(`/me/approve?id=${id}`);
  }
}
