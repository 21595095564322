import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateService } from 'src/app/services/date.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-myleaves',
  templateUrl: './myleaves.component.html',
  styleUrls: ['./myleaves.component.css']
})
export class MyleavesComponent implements OnInit {

  myleaves: any = [];
  displayedColumns: string[] = ['managerName','absenceTypeName','fromDate','toDate','days','statusName','approve'];
  year = this.dateService.yearsfirstday(new Date());

  constructor(private router: Router,private leaveService: LeaveService,private dateService:DateService) { }

  ngOnInit(): void {
    this.leaveService.myleaves(this.year.getFullYear()).subscribe((b) => (this.myleaves = b));
  }

  approve(id)
  {
    if(confirm("მოხდეს განაცხადის გაუქმება?"))
    this.leaveService.reject(id)
      .subscribe((b) =>
      {
        this.leaveService.myleaves(this.year.getFullYear()).subscribe((b) => (this.myleaves = b));
      }
      );
  }

  refreshData(value: Date) {
    if (!this.dateService.equal(value, this.dateService.yearsfirstday(this.year)))
        this.year = this.dateService.yearsfirstday(value);
        this.leaveService.myleaves(this.year.getFullYear()).subscribe((b) => (this.myleaves = b));
}

}
