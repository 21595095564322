<a mat-list-item (click)="toggleMenu()" class="header-item" [ngClass]="{'active': showMenu}">
  <div class="nav_icon">
    <img src="../../../assets/svg/{{menuItem.icon}}.svg" />
  </div>
  {{menuItem.caption}}
  <img src="../../../assets/svg/chevron_right.svg" class="chevron_right" />
</a>

<mat-nav-list class="sidenav-submenu" *ngIf="(showMenu && item==menuItem.id)">
  <ng-container *ngFor="let smi of menuItem.menuItems">
      <!-- <ng-container *ngIf="!smi.isLocal">
          <a mat-list-item (click)="this.nativeWindow.open(smi.url,'_self');">{{smi.caption}} {{currentMenu}}</a>
      </ng-container> -->
      <ng-container>
          <a mat-list-item  [ngClass]="(router.url ==smi.url)?'active':''"  [routerLink]="['/'+smi.url]" >{{smi.caption}} </a>
      </ng-container>

  </ng-container>
</mat-nav-list>



