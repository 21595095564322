<mat-tab-group #tabTurnstile style="margin-top: 20px" class="home_tabs">

  <mat-tab >
    <ng-template mat-tab-label>
      <img src="../../../assets/svg/cake.svg" />
      <span>დაბადების დღეს ვულოცავთ</span>
    </ng-template>
    <app-bdays fxFlex="49%" #bday></app-bdays>
  </mat-tab>
  <mat-tab >
    <ng-template mat-tab-label>
      <img src="../../../assets/svg/user_check.svg" />
      <span>ახალი თანამშრომლები</span>
    </ng-template>
    <app-newemps fxFlex="49%" #newemps></app-newemps>
  </mat-tab>
  <mat-tab >
    <ng-template mat-tab-label>
      <img src="../../../assets/svg/user_alt.svg" />
      <span>ყოფილი თანამშრომლები</span>
    </ng-template>
    <app-exemps fxFlex="49%" #bday></app-exemps>
  </mat-tab>
</mat-tab-group>
