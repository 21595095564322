import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
  @Input() timeheader
  displayedColumns: string[] = ['dateText','hoursDisplayText','overtime'];
  constructor() { }

  ngOnInit(): void {
  }

  getTotalHours() {
    return this.timeheader.timeRecords.map(t => t.hours).filter(f=>f<25).reduce((acc, value) => acc + value, 0);
  }

  getTotalOT() {
    return this.timeheader.timeRecords.map(t => t.overtime).filter(f=>f<25).reduce((acc, value) => acc + value, 0);
  }
}
