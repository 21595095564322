import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LeaveService } from "src/app/services/leave.service";
import { DateService } from "src/app/services/date.service";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-subordinatesleaves",
  templateUrl: "./subordinates.component.html",
  styleUrls: ["./subordinates.component.css"],
})
export class SubordinatesComponent implements OnInit {
  leaves: any = [];
  dataSource: any;
  displayedColumns: string[] = [
    "personName",
    "absenceTypeName",
    "fromDate",
    "toDate",
    "days",
    "statusName",
  ];
  year = this.dateService.yearsfirstday(new Date());

  constructor(
    private router: Router,
    private leaveService: LeaveService,
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    this.refreshData(this.year);
  }

  

  refreshData(value: Date) {
    if (
      !this.dateService.equal(value, this.dateService.yearsfirstday(this.year))
    )
      this.year = this.dateService.yearsfirstday(value);
    this.leaveService.subordinates(this.year.getFullYear()).subscribe((b) => {
      this.leaves = b;
      this.dataSource = new MatTableDataSource(this.leaves);
      this.dataSource.filterPredicate = function (record, filter) {
        return record.personName.includes(filter);
      };
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
}
