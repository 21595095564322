import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class DataService {
  readonly apiBaseURI = "https://api.dhr.ge";

  constructor(private http: HttpClient) {

  }

  dictionary(id:number) {
    return this.http.get(this.apiBaseURI + `/dictionaries/?id=${id}`);
  }
}
