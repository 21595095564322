import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/services/employee.service";
import { TimesheetService } from "src/app/services/timesheet.service";

@Component({
  selector: "app-managertimesheet",
  templateUrl: "./managertimesheet.component.html",
  styleUrls: ["./managertimesheet.component.css"],
})
export class ManagertimesheetComponent implements OnInit {
  myemployees: any = [];
  selectedEmployeeId: any;
  emptimeheader: any;

  constructor(
    private timesheetService: TimesheetService,
    private employeeService: EmployeeService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.employeeService.getmyemployees().subscribe((emps) => {
      this.myemployees = emps;
    });
  }

  public onInputChanged(id) {
    this.emptimeheader = null;
    this.selectedEmployeeId = id;
    this.changetimesheet();
  }

  public onOptionsSelected(event) {
    this.emptimeheader = null;
    this.selectedEmployeeId = event.value;
    this.changetimesheet();
  }

  private changetimesheet() {
    var date = new Date();
    return this.timesheetService
      .timesheet(
        this.selectedEmployeeId,
        this.datePipe.transform(date, "yyyy-MM-dd")
      )
      .subscribe((b) => {
        this.emptimeheader = b;
      });
  }
}
