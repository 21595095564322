import { Component, HostBinding, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import "../../../classes/ext.js";

@Component({
  selector: 'app-exemps',
  templateUrl: './exemps.component.html',
  styleUrls: ['./exemps.component.scss']
})
export class ExempsComponent implements OnInit {

  exemps: any;
  @HostBinding('hidden')
  isHidden: boolean = false;
  constructor(private employeeService: EmployeeService) { }


  ngOnInit() {
      this.employeeService.exemps().subscribe(emps => {
          this.exemps = emps;
          this.isHidden = this.exemps.isempty();
      });
  }

}
