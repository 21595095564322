<mat-card class="modal-card">
   <div class="date">{{employee.date | date: 'd MMMM'}} </div>
  <div>
    <app-image [imageId]="imageId"></app-image>
  </div>
  <div class="empname">{{employee.fullName}} </div>
  <hr>
  <div class="detail">{{employee.positionName}}</div>
  <div class="detail" *ngIf="employee.structureName">{{employee.structureName}}</div>
  <br />
    <div class="contact" *ngIf="employee.mobile">
  <mat-icon class="icon-small">stay_current_portrait</mat-icon>
  {{employee.mobiles|phone}}
  </div>
  <div class="icons-row">
      <a *ngIf="employee.companyEmail" [href]="'mailto:' + employee.companyEmail"><mat-icon class="icon-middle" matTooltip="მეილის გაგზავნა">mail</mat-icon></a>
  </div>
</mat-card>
