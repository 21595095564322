import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
        datetimeInput: 'DD/MM/YYYY hh:mm',
        timeInput: "hh:mm",
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        fullPickerInput: 'DD/MM/YYYY hh:mm',
        datetimeInput: 'DD/MM/YYYY hh:mm',
        timeInput: 'hh:mm',
        timePickerInput: 'hh:mm',
        monthYearLabel: 'MMM YYYY',
    },
};

@NgModule({
    imports: [
        MatSidenavModule,
        MatGridListModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatPaginatorModule
    ],
    exports: [
        MatSidenavModule,
        MatToolbarModule,
        MatGridListModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatPaginatorModule
    ],
    providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class MaterialModule { }
