import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly AuthBaseURI = 'https://auth.dhr.ge';
  readonly apiBaseURI = 'https://api.dhr.ge';
  readonly docBaseURI = 'https://doc.dhr.ge';
  //readonly BaseURI = 'https://localhost:44342';



  constructor(private fb: FormBuilder, private http: HttpClient) {

  }

  formModel = this.fb.group({
    UserName: ['', Validators.required],
    Email: ['', Validators.email],
    FullName: [''],
    Passwords: this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(4)]],
      ConfirmPassword: ['', Validators.required]
    }, { validator: this.comparePasswords })

  });

  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('ConfirmPassword');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Password').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  register() {
    var body = {
      UserName: this.formModel.value.UserName,
      Email: this.formModel.value.Email,
      FullName: this.formModel.value.FullName,
      Password: this.formModel.value.Passwords.Password
    };
    return this.http.post(this.AuthBaseURI + '/ApplicationUser/Register', body);
  }

  login(formData) {
    return this.http.post(this.AuthBaseURI + '/auth/login', formData);
  }


  changepass(formData) {
    return this.http.post(this.AuthBaseURI + '/auth/change', formData);
  }

  changerequest(formData) {
    return this.http.post(this.AuthBaseURI + '/auth/changerequest', formData);
  }

  getUserProfile() {
    return this.http.get(this.apiBaseURI + '/employees/me');
  }

  getMyCompany() {
    return this.http.get(this.apiBaseURI + '/employees/mycompany');
  }

}
