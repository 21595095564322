import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuItem } from "src/models/menu.item";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  readonly apiBaseURI = "https://api.dhr.ge";
  readonly docBaseURI = "https://doc.dhr.ge";

  constructor(private http: HttpClient) {

  }

  bdays(allbirthdays:boolean) {
    return this.http.get(this.apiBaseURI + `/employees/bdays?allbirthdays=${allbirthdays}`);
  }

  newemps() {
    return this.http.get(this.apiBaseURI + `/employees/newemps`);
  }

  exemps() {
    return this.http.get(this.apiBaseURI + `/employees/exemps`);
  }

  mymenu() {
    return this.http.get(this.apiBaseURI + `/employees/menu`);
  }


  getemployeesbyName(name: string) {
    return this.http.get(this.apiBaseURI +`/employees?name=${name}`);
}

getemployeesbyCode(code: string) {
  return this.http.get(this.apiBaseURI +`/employees?code=${code}`);
}

getallemployees() {
  return this.http.get(this.apiBaseURI +`/employees`);
}

getmyemployees() {
  return this.http.get(this.apiBaseURI +`/employees/myemployees`);
}

}
