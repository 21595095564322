<div class="footer">
    
    <div class="box">
        <div class="rate">
          <table>
            <tr>
              <td><app-rate></app-rate></td>
              <td><app-weather></app-weather></td>
            </tr>
          </table>
        </div>
      </div>
</div>