<app-yearpicker [(date)]="year" (YearChange)="refreshData($event)"></app-yearpicker>
<mat-form-field appearance="standard">
  <mat-label>ძებნა</mat-label>
  <input matInput placeholder="თანამშრომლის მიხედვით" #input (keyup)="applyFilter($event)">
</mat-form-field>
<table *ngIf="leaves.length > 0" mat-table class="mat-elevation-z8 table-content" [dataSource]="dataSource">
  <ng-container matColumnDef="personName">
    <th mat-header-cell *matHeaderCellDef> სახელი, გვარი</th>
    <td mat-cell *matCellDef="let element"> {{element.personName}} </td>
  </ng-container>
  <ng-container matColumnDef="absenceTypeName">
    <th mat-header-cell *matHeaderCellDef> შვებულების ტიპი</th>
    <td mat-cell *matCellDef="let element"> {{element.absenceTypeName}} </td>
  </ng-container>
  <ng-container matColumnDef="fromDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებაში გასვლის თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.fromDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="toDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებიდან დაბრუნების თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.toDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef> კალენდარული დღეების რაოდენობა</th>
    <td mat-cell *matCellDef="let element"> {{element.days}} </td>
  </ng-container>
  <ng-container matColumnDef="statusName">
    <th mat-header-cell *matHeaderCellDef> სტატუსი</th>
    <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
