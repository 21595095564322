<section class="home-content users_list_wrapper">
  <span class="content-header">
    <img src="../../../../assets/svg/users_color.svg" /> <span class="aligned-with-icon">თანამშრომლები</span>
    </span>
  <div fxLayout="row wrap " fxLayoutGap="0.5%" class="cards-container" >
      <app-searchedemps fxFlex="100%" #searched [employees]="employees"></app-searchedemps>
  </div>
</section>


