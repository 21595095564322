import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocService } from 'src/app/services/doc.service';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-popupcard',
  templateUrl: './popupcard.component.html',
  styleUrls: ['./popupcard.component.scss']
})
export class PopupcardComponent implements OnInit {
  imageId: number=0;

  constructor(public dialogRef: MatDialogRef<PopupcardComponent>,
    @Inject(MAT_DIALOG_DATA) public employee: any, private employeeService: EmployeeService,private docservice: DocService) { }

  ngOnInit(): void {
    this.imageId=this.employee.imageId;
  }

  onClick(): void {
    this.dialogRef.close();
}

}
