import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuNavService } from 'src/app/services/menu-nav-service.service';
import { WindowService } from 'src/app/services/window.service';


@Component({
  selector: 'app-nav-menu-item-component',
  templateUrl: './nav-menu-item-component.component.html',
  styleUrls: ['./nav-menu-item-component.component.scss']
})
export class NavMenuItemComponentComponent implements OnInit {
  item: number = 0;
  subscription: Subscription;
  events: string[] = [];
  opened: boolean;
  showMenu = false;
  @Input() menuItem: any;
  nativeWindow: any

  constructor(private winRef: WindowService, private _navService: MenuNavService, private router: Router) {
    this.nativeWindow = winRef.getNativeWindow();
  }

  ngOnInit(): void {
this.subscription = this._navService.navItem$.subscribe(
    item => {
        this.item = item;
    });

    if (this.menuItem.menuItems.some(s=>s.url==this.router.url))
    this.showMenu = true;

    

  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
}

  toggleMenu() {
    this.item = this.menuItem.id;
    this.showMenu = !this.showMenu;
    this._navService.changeNav(this.menuItem.id);
}

}
