import { Component, OnInit, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocService } from 'src/app/services/doc.service';
import { PopupcardComponent } from '../popupcard/popupcard.component';

@Component({
  selector: 'app-empcard',
  templateUrl: './empcard.component.html',
  styleUrls: ['./empcard.component.scss']
})
export class EmpcardComponent implements OnInit {
  @Input() employee
  @Input() backgroundclass: string = 'white';
  imageGuid: string='';
  constructor(public dialog: MatDialog,private docservice: DocService) { }

  @HostListener("click") onClick() {
    const dialogRef = this.dialog.open(PopupcardComponent, {
        width: '200px',
        height: '500px',
        data: this.employee
    });
  }

  ngOnInit(): void {
    this.imageGuid=this.employee.imageGuid;
  }



}
