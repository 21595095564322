<form #form="ngForm" class="login_form" autocomplete="off" (submit)="onSubmit(form)">
  <p class="login_title">სისტემაში შესვლა</p>
  <div class="form-group input_icons">
    <i class="far fa-user"></i>
    <input
      type="text"
      class="form-control"
      #UserName="ngModel"
      name="UserName"
      [(ngModel)]="formModel.UserName"
      required
      placeholder="პირადი #"
    />
  </div>
  <div class="form-group input_icons">
    <i class="fas fa-key"></i>
    <i class="fas fa-eye"></i>
    <input
      type="password"
      class="form-control"
      #Password="ngModel"
      name="Password"
      [(ngModel)]="formModel.Password"
      required
      placeholder="პაროლი"
    />
  </div>
  <div class="form-row">
    <div class="form-group col-6 remember_me">
      <input
        type="checkbox"
        #RememberMe="ngModel"
        name="RememberMe"
        [(ngModel)]="formModel.RememberMe"/>
      <i class="far fa-check-circle"></i>
      <span>დამახსოვრება</span>
    </div>
    <div class="form-group col-6 forgot_pass">
      <button type="button" (click)="restore(); $event.preventDefault()"
                class="" style="color:white!important" >
        დაგავიწყდა პაროლი?
      </button>
    </div>
    <div class="form-group col-md-6 m-0">
      <button type="button" (click)="newcomer(); $event.preventDefault()"
                class="new_user_btn" style="color:white!important" >
        ახალი მომხმარებელი
      </button>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 error_message">
      <span class="text-danger">{{ errmessage }}</span>
    </div>
  </div>
  <div class="form-row">
    <button
      type="submit"
      class="submit_btn"
      [disabled]="form.invalid"
    >
      შესვლა
    </button>
  </div>
</form>
