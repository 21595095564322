import { Component, OnInit } from '@angular/core';
import { ExchangeService } from "src/app/services/exchange.service";
import { DocService } from "src/app/services/doc.service";
import { Router } from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-empdoc',
  templateUrl: './employee.documents.component.html',
  styleUrls: ['./employee.documents.component.css']
})
export class EmployeeDocumentsComponent implements OnInit {
  employeedocuments: any = [];
  categories: any = [];
  private sub: any;
  companydocscategoryid:number;
  title:string;

  constructor(public exchangeService: ExchangeService,private docservice: DocService,location: Location, router: Router) {
   }

  ngOnInit(): void {
    this.docservice.getemployeedocuments().subscribe(recs => {
      this.employeedocuments = recs;
      const key = 'documentTypeName';
      this.categories=this.employeedocuments.map(item => item.documentTypeName)
      .filter((value, index, self) => self.indexOf(value) === index);
  });
  }

  filterFunction(docs,ct): any[] {
    return docs.filter(d => d.documentTypeName == ct);
}

}
