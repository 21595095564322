<div class="forms_title">
  <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="island-tropical" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-island-tropical fa-w-14 fa-9x"><path d="M299.39 32c-15.4 0-30 2.55-43.94 6.23C228.22 14.67 190.46 0 148.62 0 73.38 0 11.08 47.24.24 108.86A16.51 16.51 0 0 0 16.8 128h73.45C57.94 177.87 55.12 238.52 85 281.15c7.13 10.17 19.58 7.49 25.24 1.84l83.13-83.14c-8.85 57-25.63 110.52-41.67 152.15h-20.51C58.84 352 0 410.86 0 483.2A28.85 28.85 0 0 0 28.81 512h326.38A28.85 28.85 0 0 0 384 483.2c0-62.2-43.62-114.2-101.81-127.61 9.7-74.31 4.43-147.08-1.9-195.59H431.2a16.51 16.51 0 0 0 16.56-19.14C436.92 79.25 374.62 32 299.39 32zm52.55 448H32.06a99.3 99.3 0 0 1 99.13-96h121.62a99.3 99.3 0 0 1 99.13 96zM250.12 352h-64.27c17.86-48.47 30.69-116.82 37.11-181.72L233.23 160H248c6.26 46.64 11.77 119 2.12 192zM220 128L102.22 245.76c-18.69-49 7.89-121.36 73-150.68a8 8 0 0 0 4.28-10.22l-6-14.91a8 8 0 0 0-10.58-4.46A169.86 169.86 0 0 0 117.24 96h-79.9c16.44-37.53 60.83-64 111.28-64 32.72 0 63.21 10.8 85.87 30.42l12.76 11c10.81-2.8 31.44-9.42 52.14-9.42 50.45 0 94.83 26.48 111.27 64z" class=""></path></svg>
  შვებულების მოთხოვნის განაცხადი
</div>


<div class="new_holiday_form">
  <form
    novalidate
    [formGroup]="form"
    (ngSubmit)="submit()"
    class="form-horizontal"
  >
    <div class="form_user">
      <label class="control-label">თანამშრომელი: </label
      ><span *ngIf="exchangeService.me">{{ exchangeService.me.fullName }}</span>
    </div>
    <div class="form_row">
      <div class="row">
        <div class="col-sm-6">
          <label class="control-label" for="AbsenceTypeId">შვებულების ტიპი *</label
          >
        </div>
        <div class="col-sm-6">
          <select
            id="AbsenceTypeId"
            class="form-control"
            formControlName="AbsenceTypeId"
            (change)="changeabsence($event)"
          >
            <option
              *ngFor="let absenceType of absenceTypes; let i = index"
              [value]="absenceType.id"
            >
              {{ absenceType.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- <p *ngFor="let absenceType of absenceTypes; let i = index">{{ absenceType.name }}</p> -->

      <div class="row">
        <div class="col-sm-6">
          <label class="control-label" for="FromDate"
            >შვებულებაში გასვლის თარიღი *</label
          >
        </div>
        <div class="col-sm-6">
          <input matInput [matDatepicker]="frompicker" formControlName="FromDate"
          (dateChange)="getdayes($event)">
<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
<mat-datepicker #frompicker></mat-datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label class="control-label" for="ToDate"
            >შვებულებიდან დაბრუნების თარიღი *</label
          >
        </div>
        <div class="col-sm-6">
          <input matInput [matDatepicker]="topicker" formControlName="ToDate"
          (dateChange)="getdayes($event)">
<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
<mat-datepicker #topicker></mat-datepicker>
        </div>
      </div>
      <div class="row"  *ngIf="form.value.AbsenceTypeId == 500">
        <div class="col-sm-6">
          <label class="control-label" for="Acting">მოვალეობის შემსრულებელი *</label
          >
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="Acting"
            formControlName="Acting"
          />
        </div>
      </div>

      <div class="row" *ngIf="form.value.AbsenceTypeId == 505">
        <div class="col-sm-6">
          <label class="control-label" for="BusinessPlace"
            >მივლინების ადგილი *</label
          >
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="BusinessPlace"
            formControlName="BusinessPlace"
          />
        </div>
      </div>
      <div class="row" *ngIf="form.value.AbsenceTypeId == 505">
        <div class="col-sm-6">
          <label class="control-label" for="BusinessPurpose"
            >მივლინების საკითხი/თემა *</label
          >
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="BusinessPurpose"
            formControlName="BusinessPurpose"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label class="control-label" for="Comment">კომენტარი *</label
          >
        </div>
        <div class="col-sm-6">
          <textarea
            rows="6"
            class="form-control"
            id="Comment"
            formControlName="Comment"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
        </div>
      </div>

    </div>

    <div class="form_row_2" ><!-- *ngIf="days > 0" -->

    <div class="row">
      <div class="col-sm-12" >
        <label class="control-label">მიმდინარე ბალანსი: <span>{{ balance }}</span> {{isCalendarHolidays?"კალენდარული":"სამუშაო"}} დღე</label
        >
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label class="control-label"
          >მოთხოვნილი კუთვნილი შვებულების დღეების რაოდენობა: <span>{{ days }}</span> {{isCalendarHolidays?"კალენდარული":"სამუშაო"}} დღე</label
        >
      </div>
    </div>

  </div>

  <div class="form_row_3">
    <div class="row">
      <div class="col-sm-12 form_span_color">
        <label class="control-label">მენეჯერი: </label
        ><span  *ngIf="exchangeService.me">{{ exchangeService.me.managerName }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
          გაგზავნა
        </button>
      </div>
    </div>
  </div>
  </form>
</div>

<div class="alert alert-danger" role="alert" *ngIf="errors && errors.length > 0">
  <ul>
    <li *ngFor="let error of errors">
      {{ error }}
    </li>
  </ul>
</div>
<div class="alert alert-success" role="alert" *ngIf="successfulSave">
  Person saved successfully!
</div>
