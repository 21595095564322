import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modalcard',
  templateUrl: './modalcard.component.html',
  styleUrls: ['./modalcard.component.css']
})
export class ModalcardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
