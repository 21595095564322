<input type="checkbox" class="sidebar_nav_sm_btn" />
<div class="sidebar_nav_sm_btn_icon">
  <button type="button"></button>
  <img src="../../../assets/svg/bars.svg" />
  <img src="../../../assets/svg/times.svg" class="sidebar_nav_times" />
</div>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer mode="side" opened role="navigation">
    <mat-nav-list class="menulist">
      <a routerLink="/me/home">
      <div class="white_logo">
        <img src="../../../assets/img/logo_white.png" />
        <p>HR&Payroll Software<br />HR Digital Co. Ltd</p>
      </div>
    </a>
      <div class="icons">
        <!-- contact info icon -->
        <div class="icon">
          <a href="https://www.facebook.com/DHR.ge/" target="_blank"><i class="fab fa-facebook-f"></i></a>
        </div>
        <!-- contact info icon -->
        <!-- contact info icon -->
        <!-- <div class="icon">
          <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
        </div> -->
        <!-- contact info icon -->
        <!-- contact info icon -->
        <div class="icon">
          <a href="https://www.linkedin.com/in/hr-digital-corporation-2921a3221" target="_blank"><i class="fab fa-linkedin-in"></i></a>
        </div>
        <!-- contact info icon -->
        <!-- contact info icon + content -->
        <div class="icon">
          <input type="checkbox" />
          <i class="fas fa-phone"></i>
          <i class="fas fa-times"></i>
          <div class="mobile_info_wrapper">
            <a href="tel:+995 577 110 404">+995 577 110 404</a>
          </div>
        </div>
        <!-- contact info icon + content -->
        <!-- contact info icon + content -->
        <div class="icon">
          <input type="checkbox" />
          <i class="far fa-envelope-open "></i> 
          <i class="fas fa-times"></i>
          <div class="mobile_info_wrapper">
            <a href="mailto:info@digitalhr.ge">info@digitalhr.ge</a>
          </div>
        </div>
        <!-- contact info icon + content -->
        <!-- contact info icon + content -->
        <div class="icon">
          <input type="checkbox" />
          <i class="fas fa-map-pin"></i>
          <i class="fas fa-times"></i>
          <div class="mobile_info_wrapper">
            თბილისი, ალ. ყაზბეგის 34ა
          </div>
        </div>
        <!-- contact info icon + content -->
        <!-- contact info icon + content -->
        <div class="icon">
          <input type="checkbox" />
          <i class="fas fa-paper-plane"></i>
          <i class="fas fa-times"></i>
          <div class="mobile_info_wrapper">
            <a href="www.digitalhr.ge" target="_blank">www.digitalhr.ge</a>
          </div>
        </div>
        <!-- contact info icon + content -->
      </div>
      <!-- <ul>
        <li *ngFor="let a of menuItems; let index = index">{{ a.caption }}!</li>
      </ul> -->
      <app-nav-menu-item-component *ngFor="let mi of menuItems" [menuItem]="mi">
      </app-nav-menu-item-component>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="m_content_m_height">
      <router-outlet></router-outlet>
    </div>
    <app-footnav></app-footnav>
  </mat-sidenav-content>
</mat-sidenav-container>

