import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchedemps',
  templateUrl: './searchedemps.component.html',
  styleUrls: ['./searchedemps.component.css']
})
export class SearchedempsComponent implements OnInit {
  @Input() employees: any
  constructor() { }

  ngOnInit(): void {
  }

}
