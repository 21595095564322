import { Component, OnInit } from "@angular/core";
import { ExchangeService } from "../services/exchange.service";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-my",
  templateUrl: "./my.component.html",
  styleUrls: ["./my.component.css"],
})
export class MeComponent implements OnInit {
  constructor(
    private service: UserService,
    public exchangeService: ExchangeService
  ) {
    if (!exchangeService.me) {
      this.service.getUserProfile().subscribe((res) => {
        this.exchangeService.me = res;
        this.exchangeService.myid.next(this.exchangeService.me.id);
      });
    }

    if (!exchangeService.mycompany) {
      this.service.getMyCompany().subscribe((res) => {
        this.exchangeService.mycompany = res;
      });
    }

  }

  ngOnInit(): void {}
}
