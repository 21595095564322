import { Component, Input, OnInit } from "@angular/core";
import { DocService } from "src/app/services/doc.service";
import * as FileSaver from "file-saver";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-pdf",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.css"],
})
export class PdfComponent implements OnInit {
  PdfToShow: any;
  @Input() alt: string = "ჩამოტვირთე";
  @Input() name: string;
  @Input() guid: string;

  constructor(private docservice: DocService) {}
  ngOnInit(): void {}
  public download() {
    return this.docservice.getPDF(this.guid).subscribe((rec) => {
      this.saveAsBlob(rec, this.name);
    });

  }
  private saveAsBlob(data: any, name: string) {
    // var reader = new FileReader();
    // reader.readAsDataURL(data);
    // reader.onloadend = function () {
    //   var base64data = (reader.result as string).replace(/^data:.+;base64,/, '');
    //   let pdfWindow = window.open("");
    //   debugger;
    //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + base64data +"'></iframe>");
    //   return;
    // };
    //debugger;
   // const blob = new Blob([atob(data)], { type: "application/pdf" });
    const file = new File([this.b64toBlob(data.documentContent)], `${name}.pdf`, {
      type: "application/pdf",
    });

    FileSaver.saveAs(file);
  }

  private b64toBlob(data) {
    var byteString = atob(data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}

}
