import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AuthenticationStatus } from 'src/app/enums/auth-statuses';

@Component({
  selector: "change-pass",
  templateUrl: "./changepass.component.html",
  styleUrls: ['./changepass.component.scss']
})
export class ChangePassComponent implements OnInit {
  errmessage:string='';
  formModel = {
    UserName: "",
    Password: "",
    ConfirmPassword: "",
    OTC: "",
  };

  constructor(
    private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
        this.router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.formModel.UserName=this.route.snapshot.queryParamMap.get('username');
        }
      }
    );
    }

  ngOnInit() {

    }


  onSubmit(form: NgForm) {

    this.service.changepass(form.value).subscribe(
      (res: any) => {
        switch (res.status)
        {
          case AuthenticationStatus.OK:
            localStorage.setItem('token', res.token);
            this.router.navigateByUrl('/me/home');
            break;
            case AuthenticationStatus.WrongCode:
              this.errmessage="არასწორი დადასტურების კოდია";
              break;
            case AuthenticationStatus.OTCNotSent:
            this.errmessage="გთხოვთ დაელოდოთ დადასტურების კოდს";
            break;
        }
      },
      (err) => {
        if (err.status == 400)
        this.errmessage="მომხმარებელი ან პაროლი არასწორია";
        else console.log(err);
      }
    );
  }
}
