import { Component, OnInit } from '@angular/core';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-mybalance',
  templateUrl: './mybalance.component.html',
  styleUrls: ['./mybalance.component.scss']
})
export class MybalanceComponent implements OnInit {
  mybalance: any = [];
  displayedColumns: string[] = ['absenceType','maxDays','usedDays','balance'];

  constructor( private leaveService: LeaveService) { }

  ngOnInit(): void {
    this.leaveService.mybalance().subscribe((b) => (this.mybalance = b));
  }

}
