import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

   DaysDiff(from,to){
    let datefrom = new Date(from);
    let dateto = new Date(to);

    let days = Math.floor((dateto.getTime() - datefrom.getTime()) / 1000 / 60 / 60 / 24);
    return Math.max(days,0);
  }

  Format(value: string, dateFormat: string) {
    return moment(value).format(dateFormat);
}

}
