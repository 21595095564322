import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  readonly apiBaseURI = "https://api.dhr.ge";

  constructor(private http: HttpClient) {

  }

  balance(data: any) {
    return this.http.post(this.apiBaseURI + `/absence/balance`, data);
  }


  mybalance() {
    return this.http.get(this.apiBaseURI + `/absence/mybalance`);
  }

  unapproved() {
    return this.http.get(this.apiBaseURI + `/absence/unapproved`);
  }


  subordinates(year:number) {
    return this.http.get(this.apiBaseURI + `/absence/subordinates?year=${year}`);
  }

  myleaves(year:number) {
    return this.http.get(this.apiBaseURI + `/absence/myleaves?year=${year}`);
  }

  react(data: any) {
    return this.http.post(this.apiBaseURI + `/absence/react`, data);
  }

  reject(id) {
    return this.http.get(this.apiBaseURI + `/absence/reject?id=${id}`);
  }

  getleave(id) {
    return this.http.get(this.apiBaseURI + `/absence/leave?id=${id}`);
  }



  insert(data: any) {
    return this.http.post(this.apiBaseURI +`/absence/add`, data);
}



}
