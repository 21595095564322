import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent {

  info: string | Object;
  readonly apiBaseURI = 'https://api.dhr.ge';
    constructor(private http: HttpClient) {
        this.http.get(this.apiBaseURI +`/feed/rate`).subscribe(
            data => this.info = data,
            error => this.info="კურსების ინფორმაცია დროებით მიუწვდომელია..."
        );
    }
}
