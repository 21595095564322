import { Injectable } from "@angular/core";

interface CachedImage {
  url: string;
  blob: Blob;
}
@Injectable({
  providedIn: "root",
})
export class ImageService {
  private _cacheUrls: string[] = [];
  private _cachedImages: CachedImage[] = [];
  constructor() {}
  set cacheUrls(urls: string[]) {
    this._cacheUrls = [...urls];
  }
  get cacheUrls(): string[] {
    return this._cacheUrls;
  }
  set cachedImages(image: CachedImage) {
    this._cachedImages.push(image);
  }

  checkAndCacheImage(url: string, blob: Blob) {
    if (this._cacheUrls.indexOf(url) > -1) {
    this._cachedImages.push({url, blob});
    }
    }
}
