window.eng = new Array(97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 87, 82, 84, 83, 67, 74, 90);
window.geo = new Array(4304, 4305, 4330, 4307, 4308, 4324, 4306, 4336, 4312, 4335, 4313, 4314, 4315, 4316, 4317, 4318, 4325, 4320, 4321, 4322, 4323, 4309, 4332, 4334, 4327, 4310, 4333, 4326, 4311, 4328, 4329, 4319, 4331, 91, 93, 59, 39, 44, 46, 96);



function get_selection() {
    if (document.getSelection) {
        selection = document.getSelection();
        selection = selection.replace(/\r\n\r\n/gi, "_doublecaret_");
        selection = selection.replace(/\r\n/gi, " ");
        while (selection.indexOf("  ") != -1) selection = selection.replace(/  /gi, "");
        selection = selection.replace(/_doublecaret_/gi, "\r\n\r\n");
    } else
        selection = document.selection.createRange().text;
}



function Is() {
    var agt = navigator.userAgent.toLowerCase();
    this.major = parseInt(navigator.appVersion);
    this.minor = parseFloat(navigator.appVersion);
    this.nav = (
        (agt.indexOf('mozilla') != -1)
        && (agt.indexOf('spoofer') == -1)
        && (agt.indexOf('compatible') == -1)
        && (agt.indexOf('opera') == -1)
        && (agt.indexOf('konqueror') == -1)             ///------------- taya
        && (agt.indexOf('safari') == -1)                  // ------------------ taya
        && (agt.indexOf('webtv') == -1)
        && (agt.indexOf('hotjava') == -1)
    );
    if ((agt.indexOf('mozilla') != -1) && ((agt.indexOf('konqueror') >= 0) || (agt.indexOf('safari') >= 0))) { this.nav = true; } //---- taya

    this.nav2 = (this.nav && (this.major == 2));
    this.nav3 = (this.nav && (this.major == 3));
    this.nav4 = (this.nav && (this.major == 4));
    this.nav4up = (this.nav && (this.major >= 4));
    this.navonly = (this.nav && ((agt.indexOf(";nav") != -1) ||
        (agt.indexOf("; nav") != -1)));
    this.nav6 = (this.nav && (this.major == 5));
    this.nav6up = (this.nav && (this.major >= 5));
    this.gecko = (agt.indexOf('gecko') != -1);
    this.ie = ((agt.indexOf("msie") != -1) && (agt.indexOf("opera") == -1));
    this.ie3 = (this.ie && (this.major < 4));
    this.ie4 = (this.ie && (this.major == 4) && (agt.indexOf("msie 4") != -1));
    this.ie4up = (this.ie && (this.major >= 4));
    this.ie5 = (this.ie && (this.major == 4) && (agt.indexOf("msie 5.0") != -1));
    this.ie5_5 = (this.ie && (this.major == 4) && (agt.indexOf("msie 5.5") != -1));
    this.ie5up = (this.ie && !this.ie3 && !this.ie4);
    this.ie5_5up = (this.ie && !this.ie3 && !this.ie4 && !this.ie5);
    this.ie6 = (this.ie && (this.major == 4) && (agt.indexOf("msie 6.") != -1));
    this.ie6up = (this.ie && !this.ie3 && !this.ie4 && !this.ie5 && !this.ie5_5);
    this.aol = (agt.indexOf("aol") != -1);
    this.aol3 = (this.aol && this.ie3);
    this.aol4 = (this.aol && this.ie4);
    this.aol5 = (agt.indexOf("aol 5") != -1);
    this.aol6 = (agt.indexOf("aol 6") != -1);
    this.opera = (agt.indexOf("opera") != -1);
    this.opera2 = (agt.indexOf("opera 2") != -1 || agt.indexOf("opera/2") != -1);
    this.opera3 = (agt.indexOf("opera 3") != -1 || agt.indexOf("opera/3") != -1);
    this.opera4 = (agt.indexOf("opera 4") != -1 || agt.indexOf("opera/4") != -1);
    this.opera5 = (agt.indexOf("opera 5") != -1 || agt.indexOf("opera/5") != -1);
    this.opera6 = (agt.indexOf("opera 6") != -1 || agt.indexOf("opera/6") != -1);
    this.opera7 = (agt.indexOf("opera 7") != -1 || agt.indexOf("opera/7") != -1);
    this.opera9 = (agt.indexOf("opera 9") != -1 || agt.indexOf("opera/9.50") != -1);
    this.opera7up = (this.opera && !this.opera2 && !this.opera3 && !this.opera4 && !this.opera5 && !this.opera6 && !this.opera7);
    this.webtv = (agt.indexOf("webtv") != -1);
    this.TVNavigator = ((agt.indexOf("navio") != -1) || (agt.indexOf("navio_aoltv") != -1));
    this.AOLTV = this.TVNavigator;
    this.hotjava = (agt.indexOf("hotjava") != -1);
    this.hotjava3 = (this.hotjava && (this.major == 3));
    this.hotjava3up = (this.hotjava && (this.major >= 3));
    this.konqueror = (agt.indexOf("konqueror") != -1); //--- taya
    this.safari = (agt.indexOf("safari") != -1);   //--- taya
}


function handleKeys(e) {
    var key = (window.event) ? event : e;

    if (key.altKey || key.ctrlKey) return true; //----- კვეჟო
    var theKey = e.which;

    for (var ii = 0; ii <= window.geo.length; ii++) {
        if (window.eng[ii] == e.which) {
            var f = e.target;
            var g = f.selectionStart;  //--- ak vigebt ramdeni simbolo aris ukve akrefli  kursoramede --Taya
            f.value = f.value.substring(0, f.selectionStart) //--- kursoramede sul mteli texti razaa
                + String.fromCharCode(window.geo[ii])		//-- damatebuli es axali akrefili simbolo (ukve gadakvanili) --Taya
                + f.value.substring(f.selectionEnd);  //--- da kursoris mere raz zeria ----Taya
            //f.value+=String.fromCharCode(geo[i]);
            g++;
            f.setSelectionRange(g, g);
            return true;
        }
        //	}
        //----------ends----- taya
        status = theKey;
    }


    var f1 = e.target;
    var g1 = f1.selectionStart;
    f1.value = f1.value.substring(0, f1.selectionStart) //--- kursoramede sul mteli texti razaa
        + String.fromCharCode(e.which)		//-- damatebuli es axali akrefili simbolo (ukve gadakvanili) --Taya
        + f1.value.substring(f1.selectionEnd);  //--- da kursoris mere raz zeria ----Taya

    g1++;
    f.setSelectionRange(g1, g1);



    return true;
}

define(["require", "exports"], function (require, exports) {
    exports.converttogeo = function (obj) {
        if (obj)
            obj.onkeypress = handleKeys;
        else document.onkeypress = handleKeys;
    }
});
