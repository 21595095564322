import { Component } from "@angular/core";
import { map, mergeMap } from "rxjs/operators";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { ReportsService } from "src/app/services/reports.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { DatePipe } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.scss"],
})
export class PersonalComponent {
  personal: any = [];
  imageGuid: string='';
  wagevisibility: boolean;
  private sub: any;

  constructor(
    private router: Router,
    private service: UserService,
    private reportsService: ReportsService,
    public exchangeService: ExchangeService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {
if (this.exchangeService.me)
{
  var date = new Date();
  this.reportsService.personal(this.exchangeService.me.id, this.datePipe.transform(date, "yyyy-MM-dd")).subscribe((b) =>
      {
      this.personal = b;
      this.imageGuid=this.personal.imageGuid;
      }
  );
}
else
{
    this.sub=this.exchangeService.myid.pipe(
      mergeMap(id =>
      {
        var date = new Date();
        return this.reportsService.personal(id, this.datePipe.transform(date, "yyyy-MM-dd"))
      })).subscribe((b) =>
      {
      this.personal = b;
      this.imageGuid=this.personal.imageGuid;
      }
      );
  }
}



  ngOnDestroy() {
    if (this.sub)
    this.sub.unsubscribe();
  }
}
