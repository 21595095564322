import { Component, HostBinding, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import "../../../classes/ext.js";

@Component({
  selector: 'app-newemps',
  templateUrl: './newemps.component.html',
  styleUrls: ['./newemps.component.scss']
})
export class NewempsComponent implements OnInit {

  newemps: any;
  @HostBinding('hidden')
  isHidden: boolean = false;
  constructor(private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.employeeService.newemps().subscribe(emps => {
      this.newemps = emps;
      this.isHidden = this.newemps.isempty();
  });
  }

}
