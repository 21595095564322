import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExchangeService } from './exchange.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  readonly apiBaseURI = "https://api.dhr.ge";
  constructor(private http: HttpClient,public exchangeService: ExchangeService) {

  }

  mytimesheet(id:any,date:any) {
    let data={reportType:1,id:id,date:date};
    return this.http.post(this.apiBaseURI + '/reports/mytimesheet', data);
  }

  timesheet(id:any,date:any) {
    let data={reportType:1,id:id,date:date};
    return this.http.post(this.apiBaseURI + '/reports/timesheet', data);
  }


}
