import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/services/employee.service";
import { MenuItem } from "src/models/menu.item";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  menuItems: MenuItem[] = [];
  constructor(private employeeService: EmployeeService) {}

  ngOnInit(): void {
    this.employeeService.mymenu().subscribe((mymenu) => {
      this.updateData(mymenu);
    });
  }

  updateData(mymenu) {
    this.menuItems = mymenu;
    this.menuItems.forEach(function (item, index) {
      item.id = index;
      });
  }
}
