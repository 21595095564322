import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root'
})
export class DocService {

  readonly docBaseURI = 'https://doc.dhr.ge';
  readonly apiBaseURI = "https://api.dhr.ge";

  constructor(private http: HttpClient,private cachedImages:ImageService) { }

  getImage(guid: string|null) {
    guid=guid??'';
    const url=this.docBaseURI+`/Documents?guid=${guid}`;
    const index = this.cachedImages.cacheUrls.findIndex(u => u === url);
    if (index > -1) {
      const image = this.cachedImages.cachedImages[index];
      return of(image.blob);
      }
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(blob => this.cachedImages.checkAndCacheImage(url, blob))
      );
  }

  getPDF(guid: string|null) {
    return this.http.get(this.docBaseURI + `/Documents/pdf?guid=${guid}`);
  }


  getcompanydocumentsbycategory(categoryid:number)
  {
    return this.http.get(this.apiBaseURI +`/Documents/companydocumentsbycategory?categoryid=${categoryid}`);
  }

  getemployeedocuments()
  {
    return this.http.get(this.apiBaseURI +`/Documents/employeedocuments`);
  }
}
