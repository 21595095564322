import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserComponent } from "./user/user.component";
import { UserService } from "./services/user.service";
import { LoginComponent } from "./user/login/login.component";
import { HomeComponent } from "./my/home/home.component";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { ChangePassComponent } from "./user/change/changepass.component";
import { NumbersOnlyDirective } from "./directives/numbersOnly.directive";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { TopnavComponent } from "./layout/topnav/topnav.component";
import { FootnavComponent } from "./layout/footnav/footnav.component";
import { BdaysComponent } from "./my/home/bdays/bdays.component";
import { NewempsComponent } from "./my/home/newemps/newemps.component";
import { ExempsComponent } from "./my/home/exemps/exemps.component";
import { EmpcardComponent } from "./blocks/empcard/empcard.component";
import { PopupcardComponent } from "./blocks/popupcard/popupcard.component";
import { MeComponent } from "./my/my.component";
import { MaterialModule } from "./material.module";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ModalcardComponent } from "./components/modalcard/modalcard.component";
import { MatCardModule } from "@angular/material/card";
import { RateComponent } from "./widgets/rate/rate.component";
import { WeatherComponent } from "./widgets/weather/weather.component";
import { NavMenuItemComponentComponent } from "./blocks/nav-menu-item-component/nav-menu-item-component.component";
import { EmpboardComponent } from "./my/home/empboard/empboard.component";
import { SearchedempsComponent } from "./my/home/empboard/searchedemps/searchedemps.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import localeKa from '@angular/common/locales/ka';
import { registerLocaleData } from "@angular/common";
import { AddLeaveComponent } from "./my/leaves/addleave.component";
import { MybalanceComponent } from './my/leaves/mybalance/mybalance.component';
import { UnapprovedComponent } from './my/leaves/unapproved/unapproved.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ApproveComponent } from './my/leaves/approve/approve.component';
import { MyleavesComponent } from './my/leaves/myleaves/myleaves.component';
import { PersonalComponent } from './my/reports/personal/personal.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { ImageComponent } from './blocks/image/image.component';
import { MytimesheetComponent } from './my/reports/mytimesheet/mytimesheet.component';
import { ManagertimesheetComponent } from './my/reports/managertimesheet/managertimesheet.component';
import { TimesheetComponent } from './blocks/timesheet/timesheet.component';
import { AboutComponent } from './main/about/about.component';
import { CompanyComponent } from './main/company/company.component';
import { VacancyComponent } from './main/vacancy/vacancy.component';
import { NewsComponent } from './main/news/news.component';
import { OffersComponent } from './main/offers/offers.component';
import { PdfComponent } from './blocks/pdf/pdf/pdf.component';
import { EmployeeDocumentsComponent } from "./my/personal/docs/employee.documents.component";
import { YearpickerComponent } from "./blocks/yearpicker/yearpicker.component";
import { SubordinatesComponent } from './my/leaves/subordinates/subordinates.component';
registerLocaleData(localeKa, 'ka');

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
    datetimelocalInput: "DD/MM/YYYY hh:mm",
    datetimeInput: "DD/MM/YYYY hh:mm",
    timeInput: "hh:mm",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    fullPickerInput: "DD/MM/YYYY hh:mm",
    datetimeInput: "DD/MM/YYYY hh:mm",
    timeInput: "hh:mm",
    timePickerInput: "hh:mm",
    monthYearLabel: "YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NumbersOnlyDirective,
    UserComponent,
    ChangePassComponent,
    LoginComponent,
    HomeComponent,
    SidebarComponent,
    TopnavComponent,
    FootnavComponent,
    BdaysComponent,
    NewempsComponent,
    ExempsComponent,
    EmpcardComponent,
    PopupcardComponent,
    MeComponent,
    AddLeaveComponent,
    ModalcardComponent,
    RateComponent,
    WeatherComponent,
    NavMenuItemComponentComponent,
    EmpboardComponent,
    SearchedempsComponent,
    MybalanceComponent,
    UnapprovedComponent,
    DateFormatPipe,
    ApproveComponent,
    MyleavesComponent,
    PersonalComponent,
    ImageComponent,
    MytimesheetComponent,
    ManagertimesheetComponent,
    TimesheetComponent,
    AboutComponent,
    CompanyComponent,
    EmployeeDocumentsComponent,
    VacancyComponent,
    NewsComponent,
    OffersComponent,
    PdfComponent,
    YearpickerComponent,
    SubordinatesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatCardModule,
    ToastrModule.forRoot({
      progressBar: true,
    }),
    FormsModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
  ],
  providers: [
    UserService,
    { provide: LOCALE_ID, useValue: "ka" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports: [
    SidebarComponent,
    TopnavComponent,
    FootnavComponent,
    BdaysComponent,
    NewempsComponent,
    ExempsComponent,
    EmpcardComponent,
    PopupcardComponent,
    YearpickerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
