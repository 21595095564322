import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { EmployeeService } from "src/app/services/employee.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { DocService } from "src/app/services/doc.service";
import * as convertmodule from "../../js/geolat.js";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit,OnChanges  {
  @Input() employee;
  @Input() company;
  @ViewChild("srch_ctrl", { static: true }) searchctrl_ref: ElementRef;
  @ViewChild(MatAutocompleteTrigger, {
    static: true,
    read: MatAutocompleteTrigger,
  })
  inputAutoComplit: MatAutocompleteTrigger;
  searchEmployeesCtrl = new FormControl();
  filteredEmployees: any;
  isLoading = false;
  me: any;
  imageGuid: string='';
  companyImageGuid: string='';
  companyName: string='';


  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private route: Router,
    private zone: NgZone,
    public exchangeService: ExchangeService,
    private docservice: DocService
  ) {}

  ngOnInit(): void {
    convertmodule.converttogeo(this.searchctrl_ref.nativeElement);
    this.searchctrl_ref.nativeElement.addEventListener(
      "keypress",
      this.fillautocomplete.bind(this)
    );
    this.searchctrl_ref.nativeElement.addEventListener(
      "keyup",
      this.clearautocomplete.bind(this)
    );
  }

  ngOnChanges(changes: any){

    if(changes.employee && changes.employee.currentValue){
        let employee = changes.employee.currentValue;
        this.imageGuid=employee.imageGuid;
    }
    if(changes.company && changes.company.currentValue){

      let company = changes.company.currentValue;
      console.log(company);
      this.companyImageGuid=company.imageGuid;
      this.companyName=company.name;

  }

}

  onLogout() {
    localStorage.removeItem("token");
    this.router.navigate(["/user/login"]);
  }

  showEmployees(empno) {
    this.exchangeService.empno = empno;
    this.redirectTo("me/employees");
    this.searchEmployeesCtrl.reset();
    this.filteredEmployees = [];
    this.isLoading = false;
  }

  showallemployees()
  {
    this.exchangeService.empno = "";
    this.redirectTo("me/employees");
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  fillautocomplete(e) {
    e.preventDefault();
    this.filteredEmployees = [];
    this.isLoading = false;
    let value = this.searchctrl_ref.nativeElement.value || "";
    if (value.length > 2) {
      this.isLoading = true;

      this.employeeService
        .getemployeesbyName(value)
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe((emps) => {
          this.zone.run(() => {
            this.isLoading = false;
            this.filteredEmployees = emps;
            e.stopPropagation();
            this.inputAutoComplit.openPanel();
          });
        });
    }
  }

  clearautocomplete(e) {
    if (e.code == "Backspace" || e.code == "Delete") {
      this.filteredEmployees = [];
      this.isLoading = false;
    }
  }




}
