<app-yearpicker [(date)]="year" (YearChange)="refreshData($event)"></app-yearpicker>
<table *ngIf="myleaves.length > 0" mat-table class="mat-elevation-z8 table-content" [dataSource]="myleaves">
  <ng-container matColumnDef="managerName">
    <th mat-header-cell *matHeaderCellDef>მენეჯერი</th>
    <td mat-cell *matCellDef="let element"> {{element.managerName}} </td>
  </ng-container>
  <ng-container matColumnDef="absenceTypeName">
    <th mat-header-cell *matHeaderCellDef> შვებულების ტიპი</th>
    <td mat-cell *matCellDef="let element"> {{element.absenceTypeName}} </td>
  </ng-container>
  <ng-container matColumnDef="fromDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებაში გასვლის თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.fromDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="toDate">
    <th mat-header-cell *matHeaderCellDef> შვებულებიდან დაბრუნების თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.toDate|dateFormat: 'DD/MM/YYYY'}} </td>
  </ng-container>
  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef> კალენდარული დღეების რაოდენობა</th>
    <td mat-cell *matCellDef="let element"> {{element.days}} </td>
  </ng-container>
  <ng-container matColumnDef="statusName">
    <th mat-header-cell *matHeaderCellDef> სტატუსი</th>
    <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
  </ng-container>
  <ng-container matColumnDef="approve">
    <th mat-header-cell  *matHeaderCellDef >რეაგირება</th>
    <td mat-cell *matCellDef="let element" >
      <button mat-fab aria-label="Example icon button with a heart icon" (click)="approve(element.id);" *ngIf="element.status==100" class="btn_red">
        <mat-icon>უარყოფა</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
