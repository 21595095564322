import { ToastrService } from "ngx-toastr";
import { UserService } from "../../services/user.service";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationStatus } from "../../enums/auth-statuses";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errmessage: string = "";
  formModel = {
    UserName: "",
    Password: "",
    RememberMe: false,
  };

  constructor(
    private service: UserService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("token") != null)
      this.router.navigateByUrl("/me/home");
    else {
      let rememberMe =
        localStorage.getItem("rememberCurrentUser") == "true" ? true : false;
      if (rememberMe) {
        this.formModel.RememberMe = rememberMe;
        this.formModel.UserName = localStorage.getItem("userName");
      }
      else
      this.formModel.RememberMe = false;
    }
  }

  restore() {
    if (this.formModel.UserName) {
      this.service.changerequest(this.formModel).subscribe(
        (res: any) => {
          switch (res.status) {
            case AuthenticationStatus.OK:
              this.router.navigate(["/user/changepass"], {
                queryParams: { username: this.formModel.UserName },
              });
              break;
            case AuthenticationStatus.UserPasswordNotFound:
              this.errmessage = "მომხმარებელი ან პაროლი არასწორია";
              break;
          }
        },
        (err) => {
          if (err.status == 400)
            this.errmessage = "მომხმარებელი ან პაროლი არასწორია";
          else console.log(err);
        }
      );
    }
    else
    this.errmessage = "სისტემაში შესასვლელად გთხოვთ შეიტანოთ თქვენი პირადი ნომერი";
  }

  newcomer()
  {
    if (this.formModel.UserName) {
      this.login(this.formModel);
    }
    else
    this.errmessage = "სისტემაში შესასვლელად გთხოვთ შეიტანოთ თქვენი პირადი ნომერი";
  }

  onSubmit(form: NgForm) {
    if (this.formModel.RememberMe) {
      localStorage.setItem("userName", this.formModel.UserName);
      localStorage.setItem(
        "rememberCurrentUser",
        new Boolean(this.formModel.RememberMe).toString()
      );
    }
    this.login(form.value);
  }

  private login(form) {
    this.service.login(form).subscribe(
      (res: any) => {
        switch (res.status) {
          case AuthenticationStatus.OK:
            localStorage.setItem("token", res.token);
            this.router.navigateByUrl("/me/home");
            break;
          case AuthenticationStatus.UserPasswordNotFound:
          case AuthenticationStatus.NonExistingUser:
            this.errmessage = "მომხმარებელი ან პაროლი არასწორია";
            break;
          case AuthenticationStatus.NotActivated:
            this.router.navigate(["/user/changepass"], {
              queryParams: { username: this.formModel.UserName },
            });
            break;
        }
      },
      (err) => {
        if (err.status == 400)
          this.errmessage = "მომხმარებელი ან პაროლი არასწორია";
        else
          console.log(err);
      }
    );
  }
}
