<input type="checkbox" class="tn_btn" name="tn" />
<div class="tn_btn_icon">
  <button type="button"></button>
  <img src="../../../assets/svg/search.svg" class="mci_icon_img" />
  <img src="../../../assets/svg/times.svg" class="mci_nav_times" />
</div>
<nav class="top_nav">
    <div class="white_logo">
        <img src="../../../assets/img/logo_white.png" />
        <p>HR&Payroll Software<br />HR Digital Co. Ltd</p>
    </div>
  <div class="top_search">
    <img src="../../../assets/svg/search.svg" />
    <mat-form-field>
      <input
        matInput
        #srch_ctrl
        [placeholder]="'თანამშრომლის ძებნა...'"
        [matAutocomplete]="auto"
        [formControl]="searchEmployeesCtrl"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="showEmployees($event.option.value)"
      >
        <mat-option *ngIf="isLoading" class="is-loading"
          >გთხოვთ დაელოდოთ...</mat-option
        >
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let emp of filteredEmployees" [value]="emp.code">
            <span
              ><b>{{ emp.fullName }}</b></span
            >
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <button type="button" class="top_all_users" (click)="showallemployees()">
    <img src="../../../assets/svg/users.svg" />
    <span>ყველა თანამშრომელი</span>
  </button>
  <div class="top_company">
    <app-image [imageId]="companyImageGuid" class="top_company_logo" [alt]="companyName" imageclass="max_80"></app-image>
    <!-- <button type="button">
      <img src="../../../assets/svg/arrow_down.svg" />
    </button> -->
  </div>
</nav>

<!-- for mobile -->
<input type="checkbox" class="sidebar_nav_sm_btn" name="sn" />
<div class="sidebar_nav_sm_btn_icon">
  <button type="button"></button>
  <img src="../../../assets/svg/user.svg" />
  <img src="../../../assets/svg/times.svg" class="sidebar_nav_times" />
</div>
<!-- for mobile -->

<div class="user_menu">
  <!-- for mobile -->
  <div class="white_logo">
    <img src="../../../assets/img/logo_white.png" />
    <p>HR&Payroll Software<br />HR Digital Co. Ltd</p>
  </div>
  <!-- for mobile -->
  <div class="user_pic">
    <app-image [imageId]="imageGuid"></app-image>
  </div>
  <p class="user_name" *ngIf="employee">{{ employee.fullName }}</p>
  <button type="button">
    <img src="../../../assets/svg/user_cog.svg" />
    <p>პარამეტრები</p>
  </button>
  <button type="button" (click)="onLogout()">
    <img src="../../../assets/svg/logout.svg" />
    <p>გასვლა</p>
  </button>
  <!-- for mobile -->
  <div class="user_menu_mobile">
    <p>
      <img src="../../../assets/svg/user_cog_white.svg" />
      პარამეტრები
    </p>
    <p (click)="onLogout()">
      <img src="../../../assets/svg/logout_white.svg" />
      გასვლა
    </p>
  </div>
  <div class="top_company">
    <app-image [imageId]="companyImageGuid" class="top_company_logo" imageclass="max_80"></app-image>
    <!-- <button type="button">
      <img src="../../../assets/svg/arrow_down.svg" />
    </button> -->
  </div>
  <!-- for mobile -->
</div>

<!-- for mobile -->
<div class="mobile_header">
  <a routerLink="/me/home">
    <div class="logo">
      <img src="../../../assets/img/logo_white.png" />
      <p>HR&Payroll Software<br />HR Digital Co. Ltd</p>
    </div>
  </a>
</div>

<!-- mobile contact info -->
<div class="mobile_contact_information">
  <input type="checkbox" class="mci_btn" name="mci" />
  <div class="mci_btn_icon">
    <button type="button"></button>
    <img src="../../../assets/svg/phone.svg" class="mci_icon_img" />
    <img src="../../../assets/svg/times.svg" class="mci_nav_times" />
  </div>
  <div class="mci_wrapper">
    <div class="white_logo">
      <img src="../../../assets/img/logo_white.png" />
      <p>HR&Payroll Software<br />HR Digital Co. Ltd</p>
    </div>
    <p class="mci_title">საკონტაქტო ინფორმაცია</p>
    <p>
      <i class="fas fa-phone"></i>
      <a href="tel:+995 577 110 404">+995 577 110 404</a>
    </p>
    <p>
      <i class="far fa-envelope-open"></i>
      <a href="mailto:info@digitalhr.ge">info@digitalhr.ge</a>
    </p>
    <p><i class="fas fa-map-pin"></i> <span>თბილისი, ალ. ყაზბეგის 34ა</span></p>
    <p>
      <i class="fas fa-paper-plane"></i>
      <a href="www.digitalhr.ge" target="_blank">www.digitalhr.ge</a>
    </p>
    <div class="soc_icons">
      <a href="https://www.facebook.com/DHR.ge/" target="_blank"><i class="fab fa-facebook-f"></i></a>
      <!-- <a href="#" target="_blank"><i class="fab fa-instagram"></i></a> -->
      <a href="https://www.linkedin.com/in/hr-digital-corporation-2921a3221" target="_blank"><i class="fab fa-linkedin-in"></i></a>
    </div>
  </div>
</div>
