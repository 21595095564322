import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user/user.component';

import { LoginComponent } from './user/login/login.component';
import { HomeComponent } from './my/home/home.component';
import { ChangePassComponent } from './user/change/changepass.component';
import { MeComponent } from './my/my.component';
import { EmpboardComponent } from './my/home/empboard/empboard.component';
import { AddLeaveComponent } from './my/leaves/addleave.component';
import { MybalanceComponent } from './my/leaves/mybalance/mybalance.component';
import { UnapprovedComponent } from './my/leaves/unapproved/unapproved.component';
import { ApproveComponent } from './my/leaves/approve/approve.component';
import { MyleavesComponent } from './my/leaves/myleaves/myleaves.component';
import { SubordinatesComponent } from './my/leaves/subordinates/subordinates.component';
import { PersonalComponent } from './my/reports/personal/personal.component';
import { MytimesheetComponent } from './my/reports/mytimesheet/mytimesheet.component';
import { ManagertimesheetComponent } from './my/reports/managertimesheet/managertimesheet.component';
import { CompanyComponent } from './main/company/company.component';
import { EmployeeDocumentsComponent } from './my/personal/docs/employee.documents.component';

const routes: Routes = [
  {path:'',redirectTo:'/user/login',pathMatch:'full'},
  {
    path: 'user', component: UserComponent,
    children: [
      { path: 'changepass', component: ChangePassComponent },
      { path: 'login', component: LoginComponent }
    ]
  },
  {
    path: 'me', component: MeComponent,canActivate:[AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'personal', component:PersonalComponent  },
      { path: 'employees', component: EmpboardComponent },
      { path: 'addleave', component: AddLeaveComponent },
      { path: 'mybalance', component: MybalanceComponent },
      { path: 'myleaves', component: MyleavesComponent },
      { path: 'subordinates', component: SubordinatesComponent },
      { path: 'unapproved', component: UnapprovedComponent },
      { path: 'approve', component: ApproveComponent },
      { path: 'mytimesheet', component: MytimesheetComponent },
      { path: 'managertimesheet', component: ManagertimesheetComponent },
      { path: 'docs', component: EmployeeDocumentsComponent },
    ]
  },
  {
    path: 'main', component: MeComponent,canActivate:[AuthGuard],
    children: [
      { path: 'company', component: CompanyComponent },
      { path: 'offers', component: CompanyComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
