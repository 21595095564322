import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateService {

    constructor() { }


    covertISOFieldstoDateObjects(currentObj) {
        const isDate = moment(currentObj, moment.ISO_8601).isValid();
        if (isDate) {
            return moment(currentObj, 'YYYY-MM-DD HH:mm').toDate(); // Use this to get UTC and ignore timezone (good when you need just the date)
            }
                return currentObj;
    }

    monthsfirstday(date: Date) {

        if (date)
            return new Date(date.getFullYear(), date.getMonth(), 1,23);
        else {
            var today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), 1, 23);
        }

    }


    yearsfirstday(date: Date) {

      if (date)
          return new Date(date.getFullYear(), 1, 1,23);
      else {
          var today = new Date();
          return new Date(today.getFullYear(), 1, 1, 23);
      }

  }

    getDaysInMonth(month: Date) {
        var date = this.monthsfirstday(month);
        var days = [];
        while (date.getMonth() === month.getMonth()) {
            days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }
    return days;
    }

    addmonth(date: Date, monthes: number) {
        return date.setMonth(date.getMonth() + monthes);
    }

    addyear(date: Date, years: number) {
      return date.setFullYear(date.getFullYear() + years);
  }

    equal(date1: Date, date2: Date) {
        return date1 && date2 && new Date(date1) && new Date(date2) && new Date(date1).getFullYear() === new Date(date2).getFullYear() && new Date(date1).getMonth() === new Date(date2).getMonth() && new Date(date1).getDay() === new Date(date2).getDay();
    }
}
