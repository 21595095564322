
  <p class="tabel_title">
    <img _ngcontent-bnx-c147="" src="../../../assets/img/list.png">
    ტაბელი
  </p>
  <div class="timesheet_list_wrapper"> 
<div *ngIf="timeheader else empty">
<h4>{{timeheader.month.month | date :'MMMM/y'}}</h4>
<table mat-table class="mat-elevation-z8 table-content" [dataSource]="timeheader.timeRecords">
  <ng-container matColumnDef="dateText">
    <th mat-header-cell *matHeaderCellDef>თარიღი</th>
    <td mat-cell *matCellDef="let element"> {{element.dateText}} </td>
    <td mat-footer-cell *matFooterCellDef> ჯამური საათები: </td>
  </ng-container>
  <ng-container matColumnDef="hoursDisplayText">
    <th mat-header-cell *matHeaderCellDef> საათები/ გაცდენის მიზეზი</th>
    <td mat-cell *matCellDef="let element"> {{element.hoursDisplayText}} </td>
    <td mat-footer-cell *matFooterCellDef>
     <span> {{ getTotalHours() }}</span>
  </td>
  </ng-container>
  <ng-container matColumnDef="overtime">
    <th mat-header-cell *matHeaderCellDef>ზეგანაკვეთური</th>
    <td mat-cell *matCellDef="let element"> {{element.overtime}} </td>
    <td mat-footer-cell *matFooterCellDef>
      <span> {{ getTotalOT() }}</span>
  </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
</div>
<ng-template #empty><div class="empty_timesheet">ტაბელი არ არის შევსებული</div></ng-template> 
</div>
