import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.css']
})
export class ApproveComponent implements OnInit {
  form: FormGroup;
  responce:any;
  absence: any;
  id: number;
  constructor(private formBuilder: FormBuilder,private router: Router,private activeRoute: ActivatedRoute,private leaveService: LeaveService) {
    this.activeRoute.queryParams.subscribe((qp) => {
      this.id=qp.id;

    });
    this.form = this.formBuilder.group({
      Id:0,
      ManagerId:0,
      Approve:[false],
      Comment: [""]
    });
  }

  ngOnInit(): void {


    this.leaveService.getleave(this.id).subscribe((b) =>
    {
    this.absence = b;
    }
    );

  }

  clearerrors() {
    this.responce=null;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].setErrors(null);
    });
  }

  approve()
  {
    this.form.value.Id=this.absence.id;
    this.form.value.ManagerId=this.absence.managerId;
    this.leaveService
      .react(this.form.value)
      .subscribe((b) =>
      {
      this.responce = b;
      if (this.responce.result)
      {
        this.router.navigate(["/me/unapproved"]);
      }
      }
      );
  }

}
