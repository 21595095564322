import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateService } from '../../services/date.service';

@Component({
    selector: 'app-yearpicker',
    templateUrl: './yearpicker.component.html',
    styleUrls: ['./yearpicker.component.css'],
    host: { 'class': 'month' }
})
export class YearpickerComponent implements OnInit {

    @Input() MinDate: Date;
    @Input() MaxDate: Date;
    @Input() InitalDate: Date;
    @Output() YearChange = new EventEmitter<Date>();
    value: Date;
    @Input()
    get date() {
        return this.value;
    }
    set date(val) {
        this.value = this.dateService.yearsfirstday(val);
        this.YearChange.emit(this.value);

    }

    constructor(private dateService: DateService) {

        if (typeof this.InitalDate === 'undefined')
            this.value = this.dateService.yearsfirstday(new Date());
        else
            this.value = this.dateService.yearsfirstday(this.InitalDate);
    }

    ngOnInit() {
    }

    increase() {
        if (typeof this.MaxDate === 'undefined' || this.value < this.MaxDate) {
            {
                this.dateService.addyear(this.date, 1);
                this.date = new Date(this.date.getFullYear(),1,1);
            }
        }
    }

    decrease() {
        if (typeof this.MinDate === 'undefined' || this.value > this.MinDate) {
            this.dateService.addyear(this.date, -1);
            this.date = new Date(this.date.getFullYear(),1,1);
        }
    }

}
