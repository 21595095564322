import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuItem } from "src/models/menu.item";
import { ExchangeService } from "./exchange.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  readonly apiBaseURI = "https://api.dhr.ge";
  constructor(private http: HttpClient,public exchangeService: ExchangeService) {

  }

  personal(id:any,date:any) {
    let data={reportType:110,id:id,date:date};
    return this.http.post(this.apiBaseURI + '/reports/personal', data);
  }

}
