import { Component, OnInit } from '@angular/core';
import { ExchangeService } from "src/app/services/exchange.service";
import { DocService } from "src/app/services/doc.service";
import { Router } from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  companydocuments: any = [];
  private sub: any;
  companydocscategoryid:number;
  title:string;

  constructor(public exchangeService: ExchangeService,private docservice: DocService,location: Location, router: Router) {
     router.events.subscribe(val => {
      if (location.path().indexOf("company")>=0) {
        this.companydocscategoryid=1103;
        this.title="საჭირო ინფორმაცია";
      } else {
        this.companydocscategoryid=1104;
        this.title="კორპორატიული შეთავაზებები";
      }
    });
   }

  ngOnInit(): void {
    this.docservice.getcompanydocumentsbycategory(this.companydocscategoryid).subscribe(recs => {
      this.companydocuments = recs;
  });
  }

}
