import { Component, HostBinding, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-bdays',
  templateUrl: './bdays.component.html',
  styleUrls: ['./bdays.component.scss']
})
export class BdaysComponent implements OnInit {
  bidrthdays: any;
  allbirthdays: boolean=false;
  @HostBinding('hidden')
  isHidden: boolean = false;


  constructor(private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.getbirthdays();
  }

  getbirthdays() {
    this.employeeService.bdays(this.allbirthdays).subscribe(emps => {
        this.bidrthdays = emps;
        //this.isHidden = this.bidrthdays.isempty();
    });
}

tooglebirthdays() {
    this.allbirthdays = !this.allbirthdays;
    this.getbirthdays();
}

}
